<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div>
        <el-col :span="14" style="padding-left: 10px">
          <div class="ms-title">
            <div>智慧食堂管理平台</div>
          </div>
        </el-col>
        <el-col :span="10" style="padding-left: 10px">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="ms-content"
          >
            <el-form-item prop="username" class="username">
              <el-input
                v-model="ruleForm.username"
                prefix-icon="el-icon-user"
                placeholder="用户名"
                maxlength="16"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password" class="password">
              <el-input
                type="password"
                placeholder="密码"
                prefix-icon="el-icon-key"
                show-password
                maxlength="32"
                v-model="ruleForm.password"
                @keyup.enter.native="submitForm('ruleForm')"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="captcha" class="captcha">
              <el-col :span="12" style="padding-right: 5px">
                <el-input
                  v-model="ruleForm.captcha"
                  prefix-icon="el-icon-position"
                  placeholder="验证码"
                  maxlength="32"
                >
                </el-input>
              </el-col>
              <el-col :span="12" style="padding-left: 5px">
                <el-image
                  @click="changeCaptcha"
                  :src="captchaImg"
                  class="captcha-img"
                ></el-image>
              </el-col>
            </el-form-item>
            <div class="login-btn">
              <el-button type="success" round @click="submitForm('ruleForm')"
                >登 录</el-button
              >
            </div>
          </el-form>
        </el-col>
      </div>
    </div>
  </div>
</template>

<script>
import { UserModule } from "@/api/common";
export default {
  data: function() {
    return {
      ruleForm: {
        username: "",
        password: "",
        captcha: ""
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      captchaImg: process.env.VUE_APP_API_HOST + "/captcha?random=" + Math.random()
    };
  },
  methods: {
    changeCaptcha() {
      this.captchaImg =
        process.env.VUE_APP_API_HOST + "/captcha?random=" + Math.random();
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    login() {
      let datas = {
        username: this.ruleForm.username,
        password: this.ruleForm.password,
        captcha: this.ruleForm.captcha
      };
      UserModule.getUserLogin(datas).then(res => {
        if (res.data) {
          if (res.data.err == 1) {
            this.captchaImg =
              process.env.VUE_APP_API_HOST + "/captcha?random=" + Math.random();
            this.$message({
              showClose: true,
              message: "用户名或密码错误",
              type: "error"
            });
          } else if (res.data.err == 2) {
            this.$message({
              showClose: true,
              message: "验证码错误",
              type: "error"
            });
          } else if (res.data.err == 3) {
            this.$message({
              showClose: true,
              message: "账号已停用，请联系管理员。",
              type: "error"
            });
          } else {
            let authorization = res.headers.authorization;

            sessionStorage.setItem("userId", res.data.data.userId);
            sessionStorage.setItem("userName", res.data.data.userName);
            sessionStorage.setItem("roleId", res.data.data.roleId);
            sessionStorage.setItem("roleName", res.data.data.roleName);
            localStorage.setItem("roleAction", res.data.data.roleAction);
            localStorage.setItem("authorization", authorization);

            this.$message({
              type: "success",
              message: "登陆成功!"
            });

            this.$router.push("/home");
          }
        }
      });
    }
  },
  mounted() {
    console.log(process.env)
  }
};
</script>

<style lang="scss" scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../assets/background.jpg) no-repeat center;
  background-size: cover;
}
.ms-title {
  padding-top: 60px;
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  color: #333333;
  border-bottom: 1px solid #333333;
}
.ms-logo {
  position: absolute;
  left: 30%;
  top: 40%;
  width: 350px;
  overflow: hidden;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 800px;
  margin: -190px 0 0 -375px;
  overflow: hidden;
}
.ms-content {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.8);
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.tiparea {
  text-align: left;
  font-size: 12px;
  color: #4cbb15;
  padding: 10px 0;
  .tip {
    margin-left: 54px;
  }
  .tips {
    color: red;
  }
}
::v-deep .username .el-input__inner,
::v-deep .password .el-input__inner {
  border-radius: 20px;
}
::v-deep .captcha .el-input__inner {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.captcha-img {
  height: 38px;
  width: 100%;
  cursor: pointer;
}
</style>
