import contentMain from '@/components/layout/contentMain'
import Home from '@/page/common/home'

export default [
  {
    path: '/',
    name: 'index',
    component: Home,
    role: [],
    sliderShow: true,
    meta: {
      title: '首页',
      icon: 'el-icon-house',
    },
    noDropdown: true,
  },
  {
    path: '/organization',
    name: 'organization',
    role: [],
    sliderShow: true,
    meta: {
      title: '单位管理',
      icon: 'el-icon-user'
    },
    component: contentMain,
    children: [
      {
        path: 'departmentList',
        name: 'departmentList',
        role: [],
        sliderShow: true,
        meta: {
          title: '部门列表',
          icon: 'el-icon-food', //el-icon-truck
        },
        component: () => import('@/page/organization/departmentList'),
      },
      {
        path: 'dinerList',
        name: 'dinerList',
        role: [],
        sliderShow: true,
        meta: {
          title: '人员列表',
          icon: 'el-icon-food', //el-icon-truck
        },
        component: () => import('@/page/organization/dinerList'),
      }]
  },
  {
    path: '/canteen',
    name: 'canteen',
    role: [],
    sliderShow: true,
    meta: {
      title: '食堂管理',
      icon: 'el-icon-hot-water'
    },
    component: contentMain,
    children: [
      {
        path: 'foodList',
        name: 'foodList',
        role: [],
        sliderShow: true,
        meta: {
          title: '菜品列表',
          icon: 'el-icon-food', //el-icon-truck
        },
        component: () => import('@/page/canteen/foodList'),
      },
      {
        path: 'menuList',
        name: 'menuList',
        role: [],
        sliderShow: true,
        meta: {
          title: '菜单列表',
          icon: 'el-icon-s-custom', //el-icon-truck
        },
        component: () => import('@/page/canteen/menuList'),
      },
      {
        path: 'assignList',
        name: 'assignList',
        role: [],
        sliderShow: true,
        meta: {
          title: '配餐管理',
          icon: 'el-icon-fork-spoon', //el-icon-truck
        },
        noDropdown: true,
        component: () => import('@/page/canteen/assignList'),
      },
      {
        path: 'orderList',
        name: 'orderList',
        role: [],
        sliderShow: true,
        meta: {
          title: '报餐管理',
          icon: 'el-icon-food', //el-icon-truck
        },
        component: () => import('@/page/canteen/orderList'),
      },]
  },
  {
    path: '/finance/statementList',
    name: 'statementList',
    role: [],
    sliderShow: true,
    meta: {
      title: '财务管理',
      icon: 'el-icon-shopping-cart-full'
    },
    component: () => import('@/page/finance/statementList'),
    noDropdown: true,
  },
  {
    path: '/system',
    name: 'system',
    role: [],
    sliderShow: true,
    meta: {
      title: '系统管理',
      icon: 'el-icon-setting'
    },
    component: contentMain,
    children: [
      {
        path: 'adminList',
        name: 'adminList',
        role: [],
        sliderShow: true,
        meta: {
          title: '管理员列表',
          icon: 'el-icon-food', //el-icon-truck
        },
        component: () => import('@/page/system/adminList'),
      },
      {
        path: 'cabinetList',
        name: 'cabinetList',
        role: [],
        sliderShow: true,
        meta: {
          title: '取餐柜列表',
          icon: 'el-icon-s-custom', //el-icon-truck
        },
        component: () => import('@/page/system/cabinetList'),
      },]
  },
  {
    path: '/user',
    name: 'user',
    role: [],
    sliderShow: false,
    meta: {
      title: '个人中心',
      icon: 'el-icon-setting'
    },
    component: contentMain,
    children: [
      {
        path: 'userinfo',
        name: 'userinfo',
        role: ["all"],
        sliderShow: false,
        meta: {
          title: '个人信息',
          icon: 'el-icon-date', //el-icon-truck
        },
        component: () => import('@/page/common/userinfo'),
      },
      {
        path: 'changePwd',
        name: 'changePwd',
        role: ["all"],
        sliderShow: false,
        meta: {
          title: '修改密码',
          icon: 'el-icon-date', //el-icon-truck
        },
        component: () => import('@/page/common/changePwd'),
      },
    ]
  },
]

