import {
  HttpRequest,
  apiUrl
} from "@/utils/http";

let SchoolModule = {
  getSchoolConfigInfo: function (id) {
    return HttpRequest.getRequest({
      method: "GET",
      url: `${apiUrl}/school/config`,
    });
  },
  updateSchoolInfo: function (datas) {
    return HttpRequest.getRequest({
      method: "POST",
      url: `${apiUrl}/school/update`,
      data: datas
    });
  },
  getGradeList: function (datas) {
    return HttpRequest.getRequest({
      method: "POST",
      url: `${apiUrl}/grade/list`,
      data: datas
    });
  },
  addGradeInfo: function (datas) {
    return HttpRequest.getRequest({
      method: "POST",
      url: `${apiUrl}/grade/add`,
      data: datas
    });
  },
  updateGradeUpgrade: function () {
    return HttpRequest.getRequest({
      method: "GET",
      url: `${apiUrl}/grade/upgrade`
    });
  },
  deleteGradeInfo: function (id) {
    return HttpRequest.getRequest({
      method: "GET",
      url: `${apiUrl}/grade/delete/${id}`,
    });
  },
  getDinerList: function (datas) {
    return HttpRequest.getRequest({
      method: "POST",
      url: `${apiUrl}/diner/list`,
      data: datas
    });
  },
  exportDinerList: function (datas) {
    return HttpRequest.getRequest({
      method: "POST",
      url: `${apiUrl}/diner/export`,
      data: datas
    });
  },
  importDiner: function (datas) {
    return HttpRequest.getRequest({
      method: "POST",
      url: `${apiUrl}/diner/import`,
      data: datas
    });
  },
  addDinerInfo: function (datas) {
    return HttpRequest.getRequest({
      method: "POST",
      url: `${apiUrl}/diner/add`,
      data: datas
    });
  },
  getDinerInfo: function (id) {
    return HttpRequest.getRequest({
      method: "GET",
      url: `${apiUrl}/diner/info/${id}`,
    });
  },
  updateDinerInfo: function (datas) {
    return HttpRequest.getRequest({
      method: "POST",
      url: `${apiUrl}/diner/update`,
      data: datas
    });
  },
  deleteDinerInfo: function (id) {
    return HttpRequest.getRequest({
      method: "GET",
      url: `${apiUrl}/diner/delete/${id}`,
    });
  },
  clearDinerInfo: function (id) {
    return HttpRequest.getRequest({
      method: "GET",
      url: `${apiUrl}/diner/clear/${id}`,
    });
  },
  getCabinetList: function (datas) {
    return HttpRequest.getRequest({
      method: "POST",
      url: `${apiUrl}/cabinet/list`,
      data: datas
    });
  },
  getCabinetInfo: function (id) {
    return HttpRequest.getRequest({
      method: "GET",
      url: `${apiUrl}/cabinet/info/${id}`,
    });
  },
  updateCellEnable: function (id) {
    return HttpRequest.getRequest({
      method: "GET",
      url: `${apiUrl}/cell/enable/${id}`,
    });
  },
  updateCellDisable: function (id) {
    return HttpRequest.getRequest({
      method: "GET",
      url: `${apiUrl}/cell/disable/${id}`,
    });
  },
}

export {
  SchoolModule
}